import { Link } from 'react-router-dom';
import React, { useEffect, useState  } from 'react';

import './index.css';
import { HashLink } from 'react-router-hash-link';

const Navbar = ({ activeSection }) => {

    return (
        <div className="yao-ming-nav">
            <nav className='navbar navbar-expand-lg sidenav' id="sidenav-1" data-mdb-hidden="false">
                <div className='container-fluid'>
                    <div className="inner-container">
                        {/* <Link className='navbar-brand' to='#'><img src={require('../../static/images/logo.png')} alt='' /></Link> */}
                        <div className=' navbar-collapse nav-links' id='navbarSupportedContent'>
                            <ul className='navbar-nav sidenav-menu'>
                                <li className='nav-item'>
                                    <a className='nav-link' href='https://x.com/YAOMINGFun' target='_blank'><img src={require('../../static/images/x.png')} alt='' />twitter</a>
                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link' href='https://t.me/YAOMING_Fun' target="_blank"><img src={require('../../static/images/telegram.png')} alt='' />TELEGRAm</a>
                                </li>
                            </ul>
                            {/* <div className='wallet-btn'>
                                <Link to="#">connect wallet</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </nav>
            {/* <div className="whatsapp-swipe">
                <ul>
                    <li><a href="https://dexscreener.com/solana/2GCKQBYot4uFyauVJvcZhiQP8gXgiNQJuYVAo6SVeUti" target="_blank" className="whatsapp-icon" rel="nofollow noopener"></a></li>
                    <li><a href="https://raydium.io/swap/?inputMint=sol&outputMint=2GCKQBYot4uFyauVJvcZhiQP8gXgiNQJuYVAo6SVeUti" target="_blank" className="skype-icon" rel="nofollow noopener"></a></li>
                </ul>
            </div> */}
        </div>
    );
}
export default Navbar;