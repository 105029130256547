import WOW from 'wowjs';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import React, { useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { CopyToClipboard } from 'react-copy-to-clipboard';


import Navbar from '../../components/navbar';

import './index.css';
import 'animate.css';
import '../../static/css/animate.css';

const Home = () => {

    const [activeSection, setActiveSection] = useState('');
    const [textToCopy, setTextToCopy] = useState(''); // The text you want to copy
    const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied
    useEffect(() => { new WOW.WOW({ live: true }).init() }, []);

    const setVisible = (active) => {
        if (activeSection === active) setActiveSection('');
        else setActiveSection(active);
    };

    const onCopyText = () => {
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
      };

    return (
        <div className='yao-ming-page'>
            <div>
                <Navbar activeSection={activeSection} />
                {/* Banner */}
                <VisibilitySensor onChange={() => setVisible('Banner')}>
                    <section className="banner-section" id="home">
                        <div className='video-bg'><img className='buy-img' src={require("../../static/images/banner-animation.gif")} alt="" /></div>
                        <div className="auto-container">
                            <div className="row">
                                <div className="col-12">
                                    <div className='banner-left-area'>
                                        <div className='right-dog'>
                                            <img className='buy-img' src={require("../../static/images/logo.png")} alt="" />
                                        </div>
                                        {/* <div className="btn-area">
                                        <ul>                                    
                                            <li><HashLink to="#joinme" smooth className="btn-style-one"><span>join us</span></HashLink> </li>
                                        </ul>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor>

                {/* Toknomics Section */}
                <VisibilitySensor onChange={() => setVisible('Toknomics')}>
                    <section className='toknomics-sec' id="toknomics-sec">
                        <div className='auto-container'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='sec-title wow zoomIn'>
                                        <h2><img src={require("../../static/images/toknomics-title.png")} alt="" /></h2>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='toknomics-area-boxes'>
                                        <div className='toknomics-box toknomics-box-1 wow fadeInLeft'>
                                            <div className='text-box'>
                                                <h4>Token Address</h4>
                                                <p>9gCJB....pUMp</p>
                                                <CopyToClipboard text='9gCJBVuhT4uFtwaBVnB6GGcsC2wTYsJZm48REdspUMp' onCopy={onCopyText}>
                                                    <button className='icon'><i><img src={require("../../static/images/file.png")} alt="" /></i></button>
                                                </CopyToClipboard>
                                                {copyStatus && <p style={{marginTop: '0', fontSize: '9px', position: 'absolute', width: '100%', textAlign: 'center'}}>Wallet Address Copied</p>}
                                            </div>
                                        </div>
                                        <div className='toknomics-box toknomics-box-2 wow fadeInUp'>
                                            <div className='text-box'>
                                                <h4>100% LP Burnt</h4>
                                                <i className='icon'><img src={require("../../static/images/fire.png")} alt="" /></i>
                                            </div>
                                        </div>
                                        <div className='toknomics-box toknomics-box-3 wow fadeInDown'>
                                            <div className='text-box'>
                                                <h4>Total Supply</h4>
                                                <p>100 billion</p>
                                                <i className='icon'><img src={require("../../static/images/doller.png")} alt="" /></i>
                                            </div>
                                        </div>
                                        <div className='toknomics-box toknomics-box-4 wow fadeInRight'>
                                            <div className='text-box'>
                                                <h4>Mint Authority <br />Revoked</h4>
                                                <i className='icon'><img src={require("../../static/images/user.png")} alt="" /></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor>

                {/* Rank Section */}
                <VisibilitySensor onChange={() => setVisible('Ranking')}>
                    <section className='rank-sec' id="rank-sec">
                        <div className='imgbox'>
                            <img src={require("../../static/images/animation-box.gif")} alt="" />
                        </div>
                        <div className='right-img wow fadeInRight'>
                            <img src={require("../../static/images/right-help-img.png")} alt="" />
                        </div>
                        {/* <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='left-rank-area wow fadeInLeft'>
                                        <div className='left-img'>
                                            <img src={require("../../static/images/rank-img.png")} alt="" />
                                        </div>
                                        <div className='right-img wow fadeInRight'>
                                            <div className='overlaybox'>
                                                <p>I’d help you guys, <br />but I left my ‘how to play’ manual at home!</p>
                                            </div>
                                            <img src={require("../../static/images/right-help-img.png")} alt="" />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div> */}
                    </section>
                </VisibilitySensor>

                {/* Join Section */}
                <VisibilitySensor onChange={() => setVisible('Join')}>
                    <section className='join-sec' id="joinme">
                        <div className='bottom-img'>
                            <img src={require("../../static/images/left-img.gif")} alt="" />
                        </div>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div class="txt wow fadeInUp" id="txt">
                                        <b>J</b><b>O</b><b>I</b><b>N</b> <b>T</b><b>H</b><b>E</b>
                                    </div>
                                    <div class="txt small-text  wow fadeInDown" id="txt">
                                        <b>C</b><b>O</b><b>M</b><b>M</b><b>U</b><b>N</b><b>I</b><b>T</b><b>Y</b>
                                    </div>
                                    <div className='sec-title wow zoomIn'>
                                        <p>Jump Higher, Together – Join the Yao Ming Coin Crew!</p>
                                        
                                        <ul>
                                            <li>
                                                <a className='nav-link' href='https://t.me/YAOMING_Fun' target="_blank"><img src={require('../../static/images/telegram.png')} alt='' /></a>
                                            </li>
                                            <li>
                                                <a className='nav-link' href='https://x.com/YAOMINGFun' target='_blank'><img src={require('../../static/images/x.png')} alt='' /></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </VisibilitySensor>
            </div>
        </div >
    );
}

export default Home;