
import React, { useEffect, Suspense } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from './Home/index';
import Preloader from '../components/preloader';

import '../static/css/style.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";


const App = () => {
  return (
    <div>
      <Suspense fallback={<Preloader />}>
        <ToastContainer closeOnClick position="bottom-left" />
        <Router>
          <Switch>
            <Route exact path='/' component={props => <Home {...props} />} />
            <Route exact path='/Home' component={props => <Home {...props} />} />
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;